import styled from 'styled-components'
import { useCraft } from '../lib/craft'
import Container from './layout/Container'
import Stack from './layout/Stack'
import SectionHeading from './SectionHeading'
import TinyCard from './TinyCard'

function FeaturedContent( { content } ) {
  const { pageProps: { sessions } } = useCraft()
  const cards = cardsFromContent( content, sessions )

  return (
    <Container>
      <Stack
        $top={ 4 }
        $between={ 1.5 }
        $bottom={ 5 }
        $responsive
      >
        <SectionHeading>
          Featured content
        </SectionHeading>
        <StyledGrid>
          { cards.map( card => (
            <TinyCard
              key={ card.id }
              href={ card.href }
              type={ card.type }
              title={ card.title }
            />
          ) ) }
        </StyledGrid>
      </Stack>
    </Container>
  )
}

const cardsFromContent = ( content, sessions ) => content.map( entry => ( {
  id: entry.id,
  type: typeFromEntry( entry ),
  title: entry.title,
  href: hrefFromEntry( entry, sessions ),
} ) )

const typeFromEntry = entry => {
  if ( entry.sectionHandle === 'chunks' ) {
    return entry.chunkType.length > 0 && entry.chunkType[ 0 ]?.title
  }

  if ( entry.sectionHandle === 'sessions' ) {
    return `Session ${ entry.lft / 2 }`
  }
  
  return 'Info'
}

const hrefFromEntry = ( entry, sessions ) => {
  if ( entry.sectionHandle === 'chunks' ) {
    const firstRelatedSession = sessions.find( s => s.chunks.find( c => c.id === entry.id ) )
    
    const queryString = firstRelatedSession
      ? `?sessionId=${ firstRelatedSession.id }`
      : ''

    return `${ entry.url.replace( `https://${ process.env.NEXT_PUBLIC_APP_HOSTNAME }`, '' ) }${ queryString }`
  }

  return entry.url.replace( `https://${ process.env.NEXT_PUBLIC_APP_HOSTNAME }`, '' )
}

const StyledGrid = styled.div`
  display: grid;
  margin: calc( var( --grid-gutter ) / -2 );

  > * {
    margin: calc( var( --grid-gutter ) / 2 );
  }

  @media ( min-width: 650px ) {
    grid-template-columns: repeat( 3, 1fr );
  }
`

export default FeaturedContent
