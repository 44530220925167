import styled, { css } from 'styled-components'
import Link from './Link'

function TinyCard( { 
  href = null, 
  icon = null,
  type,
  title,
} ) {
  if ( href ) {
    return (
      <StyledTinyCard $asLink>
        <Link href={ href }>
          <TinyCardContent
            icon={ icon }
            type={ type }
            title={ title }
          />
        </Link>
      </StyledTinyCard>
    )
  }

  return (
    <StyledTinyCard>
      <TinyCardContent
        icon={ icon }
        type={ type }
        title={ title }
      />
    </StyledTinyCard>
  )
}

function TinyCardContent( { type, title, icon = null } ) {
  return (
    <>
      <p>
        { icon }
        { type }
      </p>
      <h3>{ title }</h3>
    </>
  )
}

const cardWrapStyles = css`
  display: block;
  background: ${ p => p.theme.colors.ivory };
  border-radius: 20px;
  box-shadow: inset 0 0 0 1px ${ p => p.theme.colors.warmLightGrey };
  color: inherit;
  text-decoration: none;
`

export const StyledTinyCard = styled.div<{ $asLink?: boolean }>`
  ${ p => !p.$asLink && cardWrapStyles }

  ${ p => p.$asLink && css`
    display: flex;
    flex-direction: column;

    > a {
      flex-grow: 1;
      ${ cardWrapStyles }
      transition: 
        background .3s ease-out,
        color .3s ease-out;

      &:hover {
        background: ${ p.theme.colors.black };
        color: ${ p.theme.colors.white };
      }
    }
  ` }

  p:first-child {
    margin: 0;
    padding: 1rem 1.2rem;
    font-weight: 600;
    font-size: ${ p => p.theme.typo.sizes.dropped };
    border-bottom: 1px solid ${ p => p.theme.colors.warmLightGrey };
  }

  h3 {
    margin: 0;
    padding: 1.2rem;
    ${ p => p.theme.typo.sentientLight };
    font-size: ${ p => p.theme.typo.sizes.bumped };
    line-height: 1.15;
  }
`

export default TinyCard
