import styled from 'styled-components'
import Container from './layout/Container'
import Image from './Image'
import ContentBlocks from './ContentBlocks'

function HomapageContent( { image, contentBlocks } ) {
  return (
    <Container>
      <StyledHomepageContent>
        <StyledImage>
          <Image
            src={ image.url }
            alt={ image.alt }
            layout="fill"
            objectFit="cover"
            sizes="(min-width: 850px) 50vw, 100vw"
            dateModified={ image.dateModified }
            priority
          />
        </StyledImage>
        <StyledContentBlocks>
          <ContentBlocks contentBlocks={ contentBlocks } />
        </StyledContentBlocks>
      </StyledHomepageContent>
    </Container>
  )
}

const breakpoint = 850

const StyledHomepageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledImage = styled.div`
  position: relative;
  overflow: hidden;
  flex: 0 0 115%;
  margin-left: -15%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  @media ( max-width: ${ breakpoint - 1 }px ) {
    padding-bottom: 65%;
    margin-bottom: 2rem;
  }
  
  @media ( min-width: ${ breakpoint }px ) {
    flex: 0 0 calc( 62% - calc( var( --grid-gutter ) / 2 ) );
  }
`

const StyledContentBlocks = styled.div`
  flex: 0 0 100%;
  margin-left: auto;
  
  @media ( min-width: ${ breakpoint }px ) {
    flex: 0 0 calc( 50% - calc( var( --grid-gutter ) / 2 ) );
  }
`

export default HomapageContent
