import styled, { css } from 'styled-components'
import Link from './Link'

function Card( { href = null, large = false, children } ) {
  if ( href ) {
    return (
      <StyledCard 
        $large={ large }
        $asLink
      >
        <Link href={ href }>
          { children }
        </Link>
      </StyledCard>
    )
  }

  return (
    <StyledCard $large={ large }>
      { children }
    </StyledCard>
  )
}

export const CardImage = styled.div`
  position: relative;  
`

const cardWrapStyles = css`
  display: flex;
  flex-direction: column;
  padding: 1.8rem 2rem;
  border-radius: 20px;
  background: ${ p => p.theme.colors.orange };
  text-decoration: none;
  color: inherit;

  > *:last-child {
    margin-top: auto;
  }

  @media ( max-width: 350px ) {
    padding: 1rem;
  }
`

const StyledCard = styled.div<{ 
  $large: boolean,
  $asLink?: boolean,
}>`
  ${ p => !p.$asLink && cardWrapStyles }

  ${ p => p.$asLink && css`
    display: flex;
    flex-direction: column;

    > a {
      flex-grow: 1;
      ${ cardWrapStyles }
    }
  ` }

  ${ CardImage } {
    ${ p => p.$large && css`
      height: 150px;

      img {
        max-height: 100%;
        width: auto;
      }
    ` }
    
    ${ p => !p.$large && css`
      flex-grow: 1;
      min-height: 150px;
      max-width: 150px;

      img {
        max-width: 100%;
      }
    ` }
  }

  ${ p => p.$large && css`
    h3 {
      max-width: 27rem;
      margin: 1.5rem 0 0.5rem;
      ${ p.theme.typo.sentientMedium }
      font-size: ${ p.theme.typo.sizes.large };
      line-height: 1.15;
    }
    
    p {
      max-width: 27rem;
      margin: 0 0 1rem;
      ${ p.theme.typo.sentientLight }
      font-size: ${ p.theme.typo.sizes.medium };
    }
  ` }
  
  ${ p => !p.$large && css`
    h3 {
      max-width: 27rem;
      margin: 0 0 1.5rem;
      ${ p.theme.typo.sentientLight }
      font-size: ${ p.theme.typo.sizes.bumped };
    }
    
    p {
      max-width: 27rem;
      margin: 0;
      ${ p.theme.typo.sentientMedium }
      font-size: ${ p.theme.typo.sizes.bumped };
    }

    > a {
      transition:
        background .3s ease-out,
        color .3s ease-out;
      
      img {
        filter: invert( 0 );
        transition: filter .3s ease-out;
      }

      &:hover {
        background: ${ p.theme.colors.black };
        color: ${ p.theme.colors.ivory };

        img {
          filter: invert( 0.9 );
        }
      }
    }
  ` }
`

export default Card
