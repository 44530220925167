import AppChrome from '../components/AppChrome'
import Hero from '../components/Hero'
import HomepageContent from '../components/HomepageContent'
import DiscoverTheCourse from '../components/DiscoverTheCourse'
import FeaturedContent from '../components/FeaturedContent'

export { getServerSideProps } from '../graphql/homepage'

function Home( { entry: homepage } ) {
  return (
    <AppChrome withShapes>
      <Hero>
        { homepage.pageIntro }
      </Hero>
      <HomepageContent
        image={ homepage.mainImage[ 0 ] }
        contentBlocks={ homepage.contentBlocks }
      />
      <DiscoverTheCourse />
      <FeaturedContent content={ homepage.featuredContent } />
    </AppChrome>
  )
}

export default Home
