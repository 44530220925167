import styled from 'styled-components'
import Container from './layout/Container'
import Stack from './layout/Stack'
import SectionHeading from './SectionHeading'
import Card, { CardImage } from './Card'
import Image from './Image'
import ButtonLink from './ButtonLink'

function DiscoverTheCourse() {
  return (
    <Container>
      <Stack
        $top={ 2.5 }
        $between={ 1.5 }
        $responsive
      >
        <SectionHeading>
          Discover the course
        </SectionHeading>
        <div>
          <StyledGrid>
            <Card 
              large
              href="/course/what-is-energy"
            >
              <CardImage>
                <Image
                  src="https://d1bpb8miavkgm5.cloudfront.net/icons/house-icon.gif"
                  alt=""
                  width={ 981 }
                  height={ 688 }
                  layout="raw"
                  sizes="(min-width: 600px) 300px, 80vw"
                />
              </CardImage>
              <h3>Start at the beginning</h3>
              <p>
                If you’re a beginner, work your way through the course 
                step-by-step, starting from the introduction.
              </p>
              <ButtonLink>
                Begin
              </ButtonLink>
            </Card>
            <Card 
              large
              href="/course"
            >
              <CardImage>
                <Image
                  src="https://d1bpb8miavkgm5.cloudfront.net/icons/magifying-glass-icon.gif"
                  alt=""
                  width={ 1007 }
                  height={ 746 }
                  layout="raw"
                  sizes="(min-width: 600px) 300px, 80vw"
                />
              </CardImage>
              <h3>Start exploring</h3>
              <p>
                If you already have some knowledge of the subject 
                you can explore all of the topics available.
              </p>
              <ButtonLink>
                Explore
              </ButtonLink>
            </Card>
          </StyledGrid>
        </div>
      </Stack>
    </Container>
  )
}

const StyledGrid = styled.div`
  display: grid;
  margin: calc( var( --grid-gutter ) / -2 );

  > * {
    margin: calc( var( --grid-gutter ) / 2 );
  }

  @media ( min-width: 650px ) {
    grid-template-columns: 1fr 1fr;
  }
`

export default DiscoverTheCourse
